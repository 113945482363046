import "react-calendar/dist/Calendar.css";
import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import WeeklyCalendar from "../WeeklyCalendar";
import moment from "moment";
import { MedicationPageStyle } from "../../styles";
import ProgressBar from "../ProgressBar";
import MonthlyCalendar from "../MonthlyCalendar";
import { useQuery } from "react-query";
import { getLabeledMedications } from "services/api/labelMedication";

interface Props {
  tapsHistory?: any[];
  isMonthly?: boolean;
  refetch: (
    isMonthly,
    calendarOffset,
    monthCalendarOffset,
    newLabelMeds
  ) => Promise<void>;
}

const TapsCalendar = ({ tapsHistory, isMonthly, refetch }: Props) => {
  const classes = MedicationPageStyle();
  const [calendarOffset, setCalendarOffset] = useState(0);
  const [monthCalendarOffset, setMonthCalendarOffset] = useState(0);

  useQuery(
    ["label medication show", calendarOffset, isMonthly, monthCalendarOffset],
    async () => {
      if (!isMonthly) {
        setMonthCalendarOffset(0);
      }
      const getData = await getLabeledMedications();
      refetch(isMonthly, calendarOffset, monthCalendarOffset, getData);
    }
  );

  return (
    <>
      <Typography component="div" className={classes.card}>
        {!isMonthly ? (
          <WeeklyCalendar
            history={tapsHistory ?? []}
            setCalendarOffset={setCalendarOffset}
            offset={calendarOffset}
          />
        ) : (
          <MonthlyCalendar
            setMonthCalendarOffset={setMonthCalendarOffset}
            tileContent={({ date }) => {
              let dataObj: any = {};

              const tapFilter: any =
                tapsHistory?.length &&
                tapsHistory?.filter(
                  (val) =>
                    moment(date).isSame(val?.date, "day") &&
                    val?.timePeriod === 1
                );

              let resObject = {};

              if (tapFilter?.length) {
                resObject = {
                  date: tapFilter?.[0]?.date,
                  needed: 0,
                  tapped: 0,
                  unExpected: false,
                };
                let neededTotal = 0,
                  tappedTotal = 0,
                  isUnExpected = false;

                tapFilter?.map((item) => {
                  neededTotal += item?.needed;
                  tappedTotal += item?.tapped;
                  if (item?.unExpected) isUnExpected = true;
                });

                resObject["needed"] = neededTotal;
                resObject["tapped"] = tappedTotal;
                resObject["unExpected"] = isUnExpected;

                dataObj = resObject || {};
              } else {
                dataObj =
                  tapsHistory?.find(
                    ({ date: tapDate, timePeriod: timePeriod }) =>
                      moment(tapDate).isSame(moment(date), "day") &&
                      timePeriod != 1
                  ) || {};
              }
              const progress = dataObj?.needed
                ? (dataObj?.tapped / dataObj?.needed) * 100
                : 0;

              return (
                <ProgressBar
                  unExpected={dataObj?.unExpected}
                  needed={dataObj?.needed}
                  progress={progress}
                  label={moment(date).date()}
                />
              );
            }}
          />
        )}
      </Typography>
    </>
  );
};

export default TapsCalendar;
